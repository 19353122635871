.footer { bottom: 0; margin-top: 10px; width: 100%; background-color: #f5f5f5; }
/*.navbar { padding-top: 20px; margin-bottom: 0; }*/
/*.navbar-inverse { background-color: #3A3A3A; border-color: #080808; }*/
/*.navbar-inverse .navbar-brand { color: #FFFFFF; }*/
/*.navbar-inverse .navbar-brand:hover { color: #9d9d9d; }*/
/*.logo{ height: 50px; width: 215px; margin-top: -10px; zoom: 1; position: relative; }*/
/*.navbar-nav { display: block; margin-left: 10px; }*/
/*nav.navbar.open { margin-bottom: 120px; }*/
.category_h2 { margin: 0 0 10px; font-size: 1.0em; }
.img_container_bottom{ float: none; display: table-cell; vertical-align: bottom; }
.page_description {  font-family: inherit; color: #FFFFFF; text-shadow: 0 1px 2px rgba(0,0,0,0.6); margin-top: -15px; letter-spacing: 1px; } 
.money_green{ color: #004b35; }
.green{ color: #3fbabc; }
.green_bg{ background: rgb(63,186,140)!important; }
.teal_bg{ background: #21b1b2; }
.gray_dk{ color: #3a3a3a; }
.btn_blue {  color:#1c946c; }
.dark_blue{  background: rgb(28, 148, 196)!important; }
.gray_lt { color: #939393; }
.orange_dk { color: #EF8D24; }
.orange_dk_blockquote { border-left: 5px solid #EF8D24; }
.orange_dk_bg { background: rgb(246,179,60); }
.orange_lt { color: #f6b33c; }
.orange_lt_bg { background: #f6b33c; }
.dark_purple{ color:#9966cc; }
.dark_purple_bg{ background-color:#9966cc; }
.dark_purple_blockquote { border-left: 5px solid #9966cc; }
.lt_purple{ color:#cc99cc }
.lt_purple_bg{ background:#cc99cc }
.panel-default > .panel-heading { background-color: transparent; border-bottom: none; letter-spacing: 0.05em; }
.panel_no_border { border: none; box-shadow: none; margin-top: -15px; margin-left: -45px;  margin-right: -30px;  padding: 0; }
.panel_grey{ background-color: #f5f5f5; margin-left: -15px;  }
.panel_white{ background-color: #FFFFFF; margin-left: 0;  }
.panel-info { border-color: #22b2b3; }
.panel-info > .panel-heading { background-color: #22b2b3; border-color: #1c9436; color: #FFF; }
.btn-success { background-color: #3fba8c; border-color: #1c9436; color: #fff; }
.margin-left_neg_15{ margin-left: -15px;  }
.panel_grey_2column{ background-color: #f5f5f5; margin-right: 20px; }
.footer > .container { padding-right: 15px; padding-left: 15px; }
code { font-size: 80%; }
.padding_left { padding-left: 2px;  } 
.page_anchor{ display: block; height: 80px; margin-top: -80px; visibility: hidden; }
.btn-category { background: rgb(63,186,140); color: #FFFFFF; border: none; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-category:hover, .btn-category:focus { background: rgb(34,178,179); color: #FFFFFF; }
.btn-category:active { background: rgb(34,178,179); } 
.btn-category:hover, .btn-category:focus { background: rgb(34,178,179); color: #FFFFFF; }
.btn-category:active { background: rgb(34,178,179); }
.badge { background-color: #939393; border-radius: 5px; color: #fff; display: inline-block; font-size: 12px; font-weight: 700; line-height: 1; min-width: 30px; padding: 3px 7px; text-align: center; vertical-align: middle; white-space: nowrap; }
.btn-category .badge{ background-color: #939393; background: rgb(147,147,147); } 
.btn-green { background: rgb(63,186,140); color: #FFFFFF; border: none; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-green:hover, .btn-green:focus { background: rgb(28,148,196); color: #FFFFFF; }
.btn-green:active { background: rgb(34,178,179); }
.btn-teal { background: rgb(34,178,179); color: #FFFFFF; border: none; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-teal:hover, .btn-teal:focus { background: rgb(63,186,140); color: #FFFFFF; }
.btn-teal:active { background: rgb(63,186,140); } 
.btn-blue { background: rgb(28,148,196); color: #FFFFFF; border: none; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-blue:hover, .btn-blue:focus { background: rgb(63,186,140); color: #FFFFFF; }
.btn-blue:active { background: rgb(63,186,140); }
.btn-grey-lt { background: rgb(147, 147, 147); color: #EFD824; border: none; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-grey-lt:hover, .btn-grey-lt:focus { background: rgb(58,58,58); color: #FFFFFF; }
.btn-grey-lt:active { background: rgb(246,179,60); }
.btn-grey-lt { background: rgb(147, 147, 147); color: #EFD824; border: none; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-grey-lt:hover, .btn-grey-lt:focus { background: rgb(58,58,58); color: #FFFFFF; }
.btn-grey-lt:active { background: rgb(246,179,60); }
.btn-orange-dk { background: rgb(239,141,36); color: #FFFFFF; border: none; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-orange-dk:hover, .btn-orange-dk:focus { background: rgb(246,179,60); color: #FFFFFF; }
.btn-orange-dk:active { background: rgb(246,179,60); }
.btn-purple-dk { background: rgb(153,102,204); color: #FFFFFF; border: none; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-purple-dk:hover, .btn-purple-dk:focus { background: rgb(239,141,36); color: #FFFFFF; }
.btn-purple-dk:active { background: rgb(239,141,36); }
.btn-dark-h2{ display: inline-block; font-size: 22px; font-style: normal; font-weight: 400; line-height: 1; position: relative; margin-top: 0; padding-bottom: 2px; }
.btn-orange-lt { background: rgb(246,179,60); color: #FFFFFF; border: none; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-orange-lt:hover, .btn-orange-lt:focus { background: rgb(239,141,36); color: #FFFFFF; }
.btn-orange-lt:active { background: rgb(239,141,36); }
.btn-dark-gray { background: rgb(86, 86, 86); color: #FFFFFF; border: none; -moz-g !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-dark-gray:hover, .btn-dark-gray:focus { background: rgb(0,0,0); color: #FFFFFF; }
.btn-dark-gray:active { background: rgb(247, 255, 71); }
.btn-xmas { background: #146b3a; color: #FFFFFF; border:1px solid #165b33; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-xmas:hover, .btn-xmas:focus { background: #ea4630;  border: 1px solid #bb2528;  color: #FFFFFF; }
.btn-xmas:active { background: #f8b229; }
.btn-light-gray { background: rgb(0,206,209); color: #FFFFFF; border: none; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-light-gray:hover, .btn-light-gray:focus { background: rgb(32,178,170); color: #FFFFFF; }
.btn-light-gray:active { background: rgb(247, 255, 71); }
.btn-dark { background: rgb(86, 86, 86); color: #FFFFFF; border: none; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; }
.btn-dark:hover, .btn-dark:focus { background: rgb(239,141,36); color: #FFFFFF; }
.btn-dark:active { background: rgb(63,186,140); }
.category_btn{ margin-bottom:15px;  }
.min-height-210{ min-height:200px; }
.video-container { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; }
.video-container iframe, .video-container object, .video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
.margin-bottom-20{ margin-bottom: 20px; }
.margin-bottom-10{ margin-bottom:10px; margin-top:10px; }
.margin-top-10{ margin-top:10px; }
.margin-top-20{ margin-top: 20px; }
.margin-top-30{ margin-top: 30px; }
.mar_bot_10{ margin-bottom: 10px; }
.mar_top_65{ margin-top: 65px; }
.mar_top_15{ margin-top: 15px; }
.mar_top_neg_5{ margin-top: -5px; }
.mar_top_70{ margin-top: 70px; }
.mar_top_80{ margin-top: 80px; }
.home_next{ background-color: #efbd24; padding: 5px 25px; font-size: 14px; margin-left: -15px;  margin-bottom: 10px;  }
/*.down-arrow { color: white; display: inline-block; position: relative; background: #22b2b3; padding: 5px 5px 7px 5px; width: 160px; text-align: center; margin-left: 0; margin-bottom: 15px; border-radius: 4px; }*/
.down-arrow:after { content: ''; display: block; position: absolute; left: 60px; top: 100%; width: 0; height: 0; border-top: 10px solid #22b2b3; border-right: 20px solid transparent; border-bottom: 0 solid transparent; border-left: 20px solid transparent; }
.pagination > li > a:focus, .pagination > li.active > a,  .pagination > li.active > a:focus, .pagination > li.active > span, .pagination > li.active > span:focus { background: #22b2b3; border-color: #9966cc; }
.pagination > li.active > span:hover { background: #3fba8c; }
.post_list_border_bottom { border-bottom: 1px solid #d7d7d7; }
.post-list-item { background-color: #fff; border-color: #d7d7d7; border-style: solid; border-width: 1px 1px 0; display: block; }
.share_box_orange{ margin: 10px auto 10px auto; background: rgb(239, 141, 36) none repeat scroll 0 0; border: medium none; box-shadow: none !important; color: #ffffff; border-radius: 6px; line-height: 1.33333; padding: 10px 16px; }
.share_box_riddle{ background: rgb(239, 141, 36) none repeat scroll 0 0; border: medium none; box-shadow: none !important; color: #ffffff; border-radius: 6px; line-height: 1.33333; top:-1px; }
.share_box_riddle ol, ul { margin-bottom: 0; margin-top: 0; } 
.share_box_grey_lt{ background: rgb(147,147,147) none repeat scroll 0 0; border: medium none; box-shadow: none !important; color: #ffffff; border-radius: 6px; line-height: 1.33333; padding: 0 5px; }
.share_box_white{ background: rgb(255,255,255) none repeat scroll 0 0; border: medium none; box-shadow: none !important; color: #3a3a3a; border-radius: 6px; line-height: 1.33333; padding: 0 5px; }
.share_box_purple { background: rgb(153,102,204); color: #FFFFFF; border: none; -moz-box-shadow: none !important; -webkit-box-shadow: none !important; box-shadow: none !important; } .alert_white { border-color: #ddd; background: #FFFFFF; color: #fff; }
.alert_purple_dk { border-color: #694D9F; background: #9966cc; color: #fff; }
.alert_orange_dk { border-color: #694D9F; background: #EF8D24; color: #fff; }
.alert_blue { border-color: #694D9F; background: #1C94C6; color: #fff; }
.share_modal{ padding: 5px; border-radius:5px;  background: #ffffff; border: 1px solid #9966cc;}
.share_modal_header{ height: 141px; background: rgb(63,186,140); }
.share_modal_body{ font-size: 17.5px; margin: 0; padding: 20px 0 0 0; }
.share_modal_body code{ color: #3a3a3a; background: #e3e3e3; }
.share_modal_footer{ background: rgb(63,186,140); }
.anchor_white { color: #fff; outline: medium none; text-decoration: none; }
.anchor_white:hover { color:#9966cc; outline: medium #f6b33c; text-decoration: none; }
.mission_container { color: #929292; letter-spacing: 0.05em; }
.mission_panel{ border: none; box-shadow: none; }
.height-25{ height: 25px } 
.margin-top-30{ margin-top: 30px; }
.margin-left-15{ margin-left: 15px;  }
.margin_left_neg_30{ margin-left: -30px;  }
#fboverlay { opacity: 0.7; filter: alpha(opacity=60); -webkit-filter: grayscale(100%); -moz-filter: grayscale(100%); -o-filter: grayscale(100%); -ms-filter: grayscale(100%); filter: grayscale(100%); }
.rod_riddler { height: 100px; width: 100px; padding: 10px; margin-right: 10px;  }
.mobile_h1{ font-size: 30px; margin-left: -15px; }
.mobile_h2{ font-size: 30px; margin-left: -15px; }
.mobile_pagination{ font-size: 30px; margin-left: 0; }
[data-toggle="collapse"].collapsed .if-not-collapsed { display: none; }
[data-toggle="collapse"]:not(.collapsed) .if-collapsed { display: none; }
/*idiot test*/
.sidebar_ad_container{ margin-top: 15px; margin-left: auto; margin-right: auto; }
.sidebar_container{ width: 300px; margin-left: auto; margin-right: auto; }
.max_width_728{ max-width: 728px; }
.max_width_720{ max-width: 720px; } 
.top_4 { top: 4px; }
.margin_right_10{ margin-right: 10px; }
.margin_left_10{ margin-left: 10px; }
.margin_right_neg_10{ margin-right: -10px; }
.margin_right_20{ margin-right: 20px; }
.margin_left_20{ margin-left: 20px; }
.margin_left_neg_10{ margin-left: -10px; }
.margin_left_15{ margin-left: 15px; }
.margin_left_neg_15{ margin-left: -15px; }
.margin_top_5{ margin-top: 5px; }
.margin_top_10{ margin-top: 10px; }
.margin_top_15{ margin-top: 15px; }
.margin_bottom_neg_15{ margin-bottom: -15px; }
.user_ul{ padding-right: 6px; margin-left: -16px }
.padding_0{ padding: 0; }
.padding_bottom_0{ padding-bottom: 0; }
.padding_10{ padding: 10px; }
/*Pinterest Share*/
.pinterest-anchor { margin-left:10px; margin-top:10px; position:absolute; z-index:42; width:40px; height:40px; }
.pinterest-hidden { display:none; }
.pinterest-anchor:hover { display:block !important; } 
.col-centered{ float: none; margin: 0 auto; }
.ad_banner { color:#C8C8C8; display: inline-block; font-size: 14px; }
.riddle-vote{ text-align: center; opacity: 1; pointer-events: all; color: #FFFFFF; transition: opacity 200ms ease-out 0s; }
.riddle-vote-y:before { content: "\f087"; font-family: FontAwesome; }
.riddle-vote-y{ cursor: pointer; color: #FFFFFF; border: none; background: none; }
.riddle-vote-y:hover{ color: #f6b33c; }
.riddle-vote-n:before{ content: "\f088"; font-family: FontAwesome; }
.padding_top_5{ padding-top: 5px; }
.riddle-vote-n { cursor: pointer; color: #FFFFFF; border: none; background: none; }
.riddle-vote-n:hover{ color: #f6b33c; }
.cap_wrapper{ max-width: 728px; }
.cap-vote{ text-align: center; opacity: 1; pointer-events: all; color: #FFFFFF; transition: opacity 200ms ease-out 0s; }
.cap-vote::after { content: attr(data-votes); width: 100%; color: #FFFFFF; }
.cap-vote-y:before { content: "\f087"; font-family: FontAwesome; }
.cap-vote-y{ cursor: pointer; color: #FFFFFF; border: none; background: none; }
.cap-vote-y:hover{ color: #f6b33c; }
.cap-vote-n:before{ content: "\f088"; font-family: FontAwesome; }
.cap-vote-n { cursor: pointer; color: #FFFFFF; border: none; background: none; }
.cap-vote-n:hover{ color: #f6b33c; } 
.flash{ border: 1px solid transparent;  border-radius: 4px; margin-bottom: 20px; padding: 15px; background-color: #d9edf7; border-color: #bce8f1; color: #31708f; display: none; }
.u_dash { margin-top: 10px; }
.list-group-custom{ background-image: none; background-color: #cf5b95; color: #ffffff; }
.cat_list{ font-size: 18px;  margin: 5px }
.panel.noborder { border: none; box-shadow: none; }
.panel.noborder > .panel-heading { border: none; box-shadow: none; }
#page { margin: 10px; border: 1px solid #e3e3e3; }
.padding_10{ padding: 10px; }
.close_padding{ padding: 10px 10px 0 0; }
.sign_up{ margin:0px;padding:0px; font-family: inherit;-webkit-font-smoothing:antialiased;-webkit-text-size-adjust:none;color:#31373B;padding:0px;margin:0px; text-decoration: none } 
.mar_left_10{ margin-left: 10px; }
.label_button{ margin: 5px; font-size: 14px; padding: 9px 12px 10px 12px; line-height: 1.42857; font-weight: 400; }
.survey_text{ font-size: 17.5px; margin-top:10px }
.rating_schema{  visibility: hidden; }